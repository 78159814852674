import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from './components/App';

import SKILLS from "./data/skills.json";
import COURSES from "./data/courses.json";
import EXPERIENCE from "./data/experience.json";
import PROJECTS from "./data/projects.json";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App skillLists={SKILLS} courseList={COURSES} workExperience={EXPERIENCE} projectList={PROJECTS} />
  </BrowserRouter>
);
