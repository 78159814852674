import React, { useState } from "react";
import DefaultNavigation, { CloseIcon } from "./defaultNavigation";
import Footer from "./footer";
import INFO200 from "../documents/ProjectFinalPrototypeINFO200.pdf";
import INFO360 from "../documents/ProjectFinalINFO360.pdf";

function ProjectCard(props){
    const project = props.project;
    const setProjectInfo = props.setProjectInfo;
    const setOpenPopup = props.setOpenPopup; 
    const setProjectFinal = props.setProjectFinal;
    const setProjectDescription = props.setProjectDescription;

    const INFO340 = "https://github.com/info340a-sp22/project-youjinjung.git";
    const INFO442 = "https://github.com/UW-INFO442-AU22/TechGirlies/tree/main";

    const handleClick = () => {
        setProjectInfo(project);
        setOpenPopup(true);
        if (project.title === "Sustainable Chrome Extension - Design"){
            setProjectFinal(INFO200);
        } else if(project.title === "RecCreate Social Networking Application - Design") {
            setProjectFinal(INFO360);
        } else if (project.title === "Privacy Policy Web App - Application") {
            setProjectFinal(INFO340);
        } else if(project.title === "Conscious - Application") {
            setProjectFinal(INFO442);
        }
        setProjectDescription(project.description);
    }

    return(
        <div className="project">
            <button className="project-button" onClick={handleClick}>
                <div className="image">
                    <img src={project.image} alt={project.alt} />
                    {/* <h3>{project.title}</h3> */}
                </div>
            </button>
            <h3>{project.title}</h3>
            <p>{project.titleTwo}</p>
        </div>
    )
}


function ProjectMedia(props){
    const projectInfo = props.projectInfo;
    if(projectInfo?.video !== undefined){
        return(
            <iframe 
                src={projectInfo.video}
                className="popup-media"
                allowFullScreen
                frameBorder="0"
                allow="autoplay; encrypted-media"
                title="video"
            />
        )
    } else {
        // return (
        //     <img src={projectInfo.example} className="popup-media" />
        // )
    }
    // temporarily disabling alternate screenshot of project
}

function ProjectPopUp(props){
    const openPopup = props.openPopup;
    const setOpenPopup = props.setOpenPopup;
    const projectInfo = props.projectInfo;
    const projectFinal = props.projectFinal;
    const projectDescription = props.projectDescription;

    const [headerChange, setHeaderChange] = useState(false);

    const handleClose = () => {
        setOpenPopup(false)
    }
    
    const scroll = document.querySelector('#project-pop')
    if(scroll){
        scroll.addEventListener('scroll', () => {
            if(scroll.scrollTop > 50){
                setHeaderChange(true);
            } else {
                setHeaderChange(false);
            }
        })
    }

    const description = projectDescription.map((paragraph, index) => {
            return(
                <p key={index} className="project-description">{paragraph}</p>
            )
    });

    return(
        <div id="project-pop" className={`project-popup ${openPopup ? " show-popup" : ""}`}>
            <div className={`popup-header ${headerChange ? " change-background" : ""}`}>
                <h4>{projectInfo.title}</h4>
                <button className="popup-close" type="button" onClick={handleClose}>
                    <CloseIcon />
                </button>
            </div>
            <div className="popup-main">
                <div className="project-container">
                    <div className="project-link">
                        <div className="blending-div-top" />
                        <h5><a href={projectInfo.link} target="_blank" rel="noreferrer">-- View Minimum Viable Product --</a></h5>
                        <div className="blending-div-bottom" />
                    </div>
                    <ProjectMedia projectInfo={projectInfo} />
                    <div className="project-details">
                    {description}
                    </div>
                    <div className="writeup-link">
                        <div className="blending-div-top" />
                        <h5><a href={projectFinal} target="_blank" rel="noreferrer">-- View Full Project Write-Up --</a></h5>
                        <div className="blending-div-bottom" />
                    </div>
                </div>
            </div>
            <div className="popup-footer">
                <Footer />
                <small>Images sourced via Google</small>
            </div>
        </div>
    )
}

export default function PortfolioPage(props) {
    const projectList = props.projectList;
    const projectInfo = props.projectInfo;
    const [openPopup, setOpenPopup] = useState(false);

    const [projectFinal, setProjectFinal] = useState({});
    const [projectDescription, setProjectDescription] = useState([""])

    const projects = projectList.map((project) => {
        return <ProjectCard key={project.title} setProjectDescription={setProjectDescription} setProjectFinal={setProjectFinal} setOpenPopup={setOpenPopup} setProjectInfo={props.setProjectInfo} project={project} />
    })


    return(
        <div className="portfolio">
            <DefaultNavigation class={"portfolio-title"} headerText={"Welcome To My Portfolio"} />
            <main>
                <div className="projects">
                    {projects}
                </div>
                <ProjectPopUp projectDescription={projectDescription} projectFinal={projectFinal} openPopup={openPopup} setOpenPopup={setOpenPopup} projectInfo={projectInfo}  />
            </main>
                <Footer />
        </div>
    )
}