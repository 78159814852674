import React from "react";
import DefaultNavigation from "./defaultNavigation";
import Footer from "./footer";

export default function AboutPage() {
    return(
        <div className="about">
            <DefaultNavigation class={"about-title"} headerText={"About Me"} />
            <main>
                <h3>Currently being developed! Please come back soon!</h3>
            </main>
            <Footer />
        </div>
    )
}