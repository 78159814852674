import React, { useState } from 'react';
import {Routes, Route} from 'react-router-dom';
import HomePage from './homePage.js';
import AboutPage from './aboutMePage';
import PortfolioPage from './portfolioPage';
import ContactPage from './contactPage'

function App(props) {
  const [projectInfo, setProjectInfo] = useState([]);
  
  return (
    <Routes>
      <Route path="/" element={<HomePage skillLists={props.skillLists} courseList={props.courseList} workExperience={props.workExperience} />} />
      <Route path="about" element={<AboutPage />} />
      <Route path="portfolio" element={<PortfolioPage projectInfo={projectInfo} setProjectInfo={setProjectInfo} projectList={props.projectList} />} />
      <Route path="contact" element={<ContactPage />} />
    </Routes>
  );
}

export default App;
