import React from "react";
import DefaultNavigation from "./defaultNavigation";
import Footer from "./footer";

function LinkOption(props){
    return(
        <li>
            <a href={props.link} target="_blank">{props.name}</a>
        </li>
    )
}


export default function ContactPage() {
    return(
        <div className="contact">
            <DefaultNavigation />
            <main>
                <h2>Contact Me!</h2>
                <ul>
                    <LinkOption link={"https://www.linkedin.com/in/madeline-wake-0b19b4229/"} name={"LinkedIn"} />
                    <LinkOption link={"mailto: madiwake@icloud.com"} name={"Email"} />
                    <LinkOption link={"https://www.instagram.com/madiiwake/?hl=en"} name={"Instagram"} />
                </ul>
            </main>
            <Footer />
        </div>
    )
}