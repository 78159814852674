import React from "react";
import DefaultNavigation from "./defaultNavigation";
import Footer from "./footer";

function SkillLists(props){
    const skillTitle = props.skillTitle;
    const skillItems = props.skillItems;

    const skillsList = skillItems.map((skill) => 
        <li key={skill}>{skill}</li>
    );

    return(
        <ul>
            <h3>{skillTitle}</h3>
            {skillsList}
        </ul>
    )
}

function Course(props){
    return(
        <li>
            <h5>{props.className}</h5>
            <p>{props.courseNum}</p>
            <p>{props.description}</p>
        </li>
    )
}

function Job(props){
    return(
        <li>
            <h4>{props.company}</h4>
            <h5>{props.title}</h5>
            <p>{props.description}</p>
        </li>
    )
}

export default function HomePage(props){
    const skillLists = props.skillLists;
    const workExperience = props.workExperience;
    const courseList = props.courseList;

    const skillsSection = skillLists.map((skill) => {
        return <SkillLists key={skill.skillTitle} skillTitle={skill.skillTitle} skillItems={skill.skillItems} />
    });

    const courseSection = courseList.map((course) => {
        return <Course key={course.courseNum} className={course.name} courseNum={course.courseNum} description={course.description} />
    });

    const workExperienceSection = workExperience.map((job) => {
        return <Job key={job.company} company={job.company} title={job.title} description={job.description} />
    })

    return(
        <div className="home">
            <DefaultNavigation />
            <main>
                <div className="quick-info">
                    <div className="profilePicture">
                        <img className="homePicture" src="img/profilePicture.jpg" alt="Madi with brown, dark pink ombre hair, weating a black shirt and smiling at the camera. Standing in front of a pond with trees with green and orange leaves." />
                    </div>
                    <div className="bio-container">
                        <div className="bio">
                            <h2>Hi! I'm Madi.</h2>
                            <p>I am a current junior studying Informatics at the University of Washington - Seattle, specifically focusing on software development and human-computer interaction. </p>
                            <p>This past summer, I worked independently to further my knowledge relating to accessibility design and front-end development.</p>
                            <p>I am interested in exploring the use of technology in order to aid individuals with disabilities, as well as further understanding the power of front-end development and user-interface design.</p>
                        </div>
                    </div>
                </div>
                <span className="divider"></span>
                <div className="skills">
                    {skillsSection}
                </div>
                <span className="divider"></span>
                <div className="education">
                    <h3>University of Washington - Seattle</h3>
                    <h4>-- Informatics: Human-Computer Interaction --</h4>
                    <p>September 2020 - June 2024 (expected graduation date)</p>
                    <p>Current GPA: 3.89</p>
                    <h4>Relevant Courses</h4>
                    <ul className="courses">
                        {courseSection}
                    </ul>
                </div>
                <span className="divider"></span>
                <div className="work">
                    <h3>Work Experience</h3>
                    <ul>
                        {workExperienceSection}
                    </ul>
                </div>
            </main>
            <Footer />
        </div>
    )
}